<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">TRASPASO</v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="sku" :counter="15" label="SKU" :disabled="disabledSku" :filled="filledSku" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1"><v-btn @click="verSku(sku)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn></v-col>
                            <v-col cols="12" md="2">
                                <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                    Existe !
                                </v-alert>
                                <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                    no Existe !
                                </v-alert>
                                <v-alert class='text-caption' v-else dense text type="info">
                                    <span class="text-caption grey--text">Sku: 99999</span>
                                </v-alert>
                            </v-col >
                            <v-col cols="12" md="4">
                                <v-text-field class='text-caption' v-model="nombre" label="Nombre Producto" disabled filled outlined dense ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="categoria" label="Categoria" disabled filled outlined dense ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider class="grey"></v-divider>
                        <v-row class="mt-1">
                            <v-col cols="12" md="6">
                                <v-textarea rows="6" class='text-caption' no-resize outlined v-model="descripcion" label="Descripcion" disabled filled></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field class='text-caption' v-model="caracteristica" label="Caracteristica" outlined dense disabled filled></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6" class="mt-n6">
                                        <v-select class='text-caption' v-on:change="stockSku(bodega)" v-model="bodega" :items="listabodega" label="Local Origen" dense outlined :hint="tStock" persistent-hint :disabled="disabledDatos" :filled="filledDatos"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="6" class="mt-n6">
                                        <!-- <v-text-field class='text-caption' v-model="preciolocal" label="Precio" outlined dense disabled filled></v-text-field> -->
                                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="cantidad" label="Cantidad" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="mt-n6">
                                        <v-text-field class='text-caption' v-model="observa" label="Observacion" outlined dense :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" class="mt-n6">
                                        <v-select class='text-caption' v-model="ndestino" :items="listadestino" label="Local Destino" item-text="name" item-value='code' dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions style="margin-top:-25px">
                <v-spacer></v-spacer>
                <v-btn @click="newIngreso()" class="mr-5" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                <v-btn @click="saveIngreso()" class="mr-5" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Nueva Bodega</v-toolbar>
            <v-card-text class="mt-5">
                <v-text-field class='text-caption' v-model="newbodega" label="Nombre Bodega" :disabled="disabledBodega" :filled="filledBodega" outlined dense required></v-text-field>
                <v-row>
                    <v-col cols="12" md="6">
                        <div v-if="alertabodega!='si'">
                            <v-btn @click="newBodega()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        </div>
                        <div v-else>
                            <v-btn small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-alert class='text-caption' v-if="alertabodega=='si'" dense text type="success">
                            Bodega Creada con Exito !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else-if="alertabodega=='no'" dense text type="error">
                            No ha Ingresado Bodega !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else dense text type="info">
                            Dato Obligatorio !!!
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="CloseDialog()" color="indigo">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Traspaso',  
    components:{dialogo, snack}, 
    props: ['usu','contrato'], 
    data(){
        return{
            //contrato:'123',
            tStock:'',
            numeroStock:0,
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            disabledBodega:false,
            filledBodega:false,
            disabledSku:false,
            filledSku:false,
            disabledDatos:true,
            filledDatos:true,
            existe:'',
            alertabodega:'',
            newbodega:'',
            dialog:false,
            listabodega:[],
            listadestino:[],
            sku:'',
            nombre:'',
            categoria:'',
            ncategoria:0,
            descripcion:'',
            caracteristica:'',
            cantidad:'',
            bodega:'',
            nbodega:0,
            ndestino:0,
            preciolocal:'',
            observa:''            
        }
    },
    mounted(){
        this.bodegas();
    },
    methods:{
        async bodegas(){
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/bodegas/'+ this.contrato);
                const datos=response.data;
                //console.log(datos);
                this.listadestino=[];
                datos.forEach(element => {
                    this.listadestino.push({
                        'name':element.Nombre,
                        'code':element.ID
                    });
                });
                // agregamos la bodega de reciclaje
                this.listadestino.push({
                        'name':'Bodega de Reciclaje',
                        'code':999
                });         
            } catch (error) {
                console.log(error)
            }
        },
        restoDatos(bool){
            this.disabledDatos=bool,
            this.filledDatos=bool
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.nombre='';
            this.categoria='';
            this.ncategoria=0;
            this.descripcion='';
            this.caracteristica='';
            this.cantidad='';
            this.bodega='';
            this.preciolocal='';
            this.listabodega=[];
            //this.listadestino=[];
            this.tStock='';
            this.numeroStock=0;
            this.nbodega=0;
            this.ndestino=0;
            this.observa=''
        },
        async verSku(xSku){
             this.loading=true;
             this.msgbox=false;
             this.clsInput();
            if(xSku.trim()!=''){
                this.disabledSku=true;
                this.filledSku=true;
                const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/producto/'+this.contrato+'/'+xSku);
                const datos=response.data;
                //console.log(datos);
                if (datos[0].existe=='no'){
                    this.existe='no'
                }
                if (datos[0].existe=='error'){
                    this.Msg='Conexion Inestable, intentelo mas tarde'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                    this.newIngreso()
                }
                if (datos[0].existe=='si'){               
                    this.existe='si';
                    this.sku=datos[0].Sku;
                    this.nombre=datos[0].Nombre;
                    this.ncategoria=datos[0].Categoria;
                    this.descripcion=datos[0].Descripcion;
                    this.caracteristica=datos[0].Caracteristica;
                    this.preciolocal=datos[0].PrecioLocal;
                    await this.nombreCategoria(this.ncategoria)
                    this.SelectBodegas();
                    this.restoDatos(false);
                } 
            } else {
                this.existe=''
            }
            this.loading=false;
        },
        async nombreCategoria(numeroCategoria){
            const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/categorias/'+ this.contrato);
            const datos=response.data;
            //console.log(datos);
            datos.forEach(element => {
                if(element.ID==numeroCategoria){
                    this.categoria=element.Nombre
                }
            });
        },
        async saveIngreso(){
            this.loading=true;
            this.msgbox=false;
            if (this.contrato!='' && this.cantidad!='' && this.bodega!='' && this.nbodega!=0 && this.ndestino!=0 &&
                this.nombre!='' && this.categoria!='' && this.descripcion!='' &&
                this.caracteristica!='' && this.preciolocal!='' && this.sku!=''){
                if(this.numeroStock>=parseInt(this.cantidad)){
                    if (this.ndestino!=999){
                        //Egreso Origen
                        var parametros={
                            'contrato': this.contrato,
                            'sku': this.sku,
                            'cantidad': this.cantidad,
                            'signo': '0-',
                            'bodega': this.nbodega,
                            'usuario':this.usu,
                            'observa':this.observa,
                            'origen':'Egreso'
                        };
                        const response = await axios.post(process.env.VUE_APP_URL + '/public/api/inv/stock',parametros);
                        const datos=response.data;
                        if (datos=='si'){ 
                            //ingreso Destino                       
                            var parametros={
                                'contrato': this.contrato,
                                'sku': this.sku,
                                'cantidad': this.cantidad,
                                'signo': '0+',
                                'bodega': this.ndestino,
                                'usuario':this.usu,
                                'observa':this.observa,
                                'origen':'Ingreso'
                            };
                            const response = await axios.post(process.env.VUE_APP_URL + '/public/api/inv/stock',parametros);
                            const datos=response.data;
                            if (datos=='si'){
                                this.existe='si';               
                                this.Msg='Movimiento Existoso !!!'
                                this.msgbox=true;
                                this.color='success';
                                this.showSnack();
                                this.totalStock();
                            } else {
                                this.Msg='Conexion Inestable, Destino'
                                this.msgbox=true;
                                this.color='red';
                                this.showSnack();
                                this.newIngreso()
                            }
                        } else {
                            this.Msg='Conexion Inestable, Origen'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                            this.newIngreso()
                        }
                    } else {
                        var parametros={
                            'contrato': this.contrato,
                            'sku': this.sku,
                            'cantidad': this.cantidad,
                            'signo': '0-',
                            'bodega': this.nbodega,
                            'usuario':this.usu,
                            'observa':this.observa,
                            'origen':'Reciclaje'
                        };
                        const response = await axios.post(process.env.VUE_APP_URL + '/public/api/inv/stock',parametros);
                        const datos=response.data;
                        if (datos=='si'){
                            this.existe='si';               
                            this.Msg='Movimiento Existoso !!!'
                            this.msgbox=true;
                            this.color='success';
                            this.showSnack();
                            this.totalStock();
                        } else {
                            this.Msg='Conexion Inestable, Reciclaje'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                            this.newIngreso()
                        }
                    }
                } else {
                    this.Msg='Cantidad Supera Stock'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                    this.Msg='Todos los datos obligatorios'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
            }
            this.loading=false;
        },
        newIngreso(){
            this.loading=true;
            this.msgbox=false;
            this.sku='';
            this.clsInput();
            this.disabledSku=false;
            this.filledSku=false;
            this.existe='';
            this.loading=false;
            this.restoDatos(true);
        },
        async SelectBodegas(){
            const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/stock/bodegas/producto/'+ this.contrato +'/'+ this.sku);
            const datos=response.data;
            //console.log(datos);
            datos.forEach(element => {
                this.listabodega.push(element.Nombre);
            });
        },
        async stockSku(a){
            this.cantidad='';
            await this.numeroBodega(a);
            if (this.nbodega!=0){
                await this.totalStock();
            } else {
                this.tStock='Error de Bodega';
                this.numeroStock=0;
            }
        },
        async numeroBodega(txtbodega){
            const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/bodegas/'+ this.contrato);
            const datos=response.data;
            datos.forEach(element => {
                if(element.Nombre==txtbodega){
                    this.nbodega=element.ID
                }
            });
        },
        async totalStock(){
            const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/stock/bodegas/producto/total/'+ this.contrato +'/'+ this.nbodega + '/' + this.sku);
            const datos=response.data;
            if (datos[0].existe=='si'){ 
                this.tStock='Total Stock:'+datos[0].Total;
                this.numeroStock=datos[0].Total;
            }
            if (datos[0].existe=='no'){ 
                this.tStock='Sin Stock';
                this.numeroStock=0;
            } 
            if (datos[0].existe=='error'){ 
                this.tStock='Error de Stock';
                this.numeroStock=0;
            } 
        }
    }
}
</script>