const URLactual = window.location.href;
let result;
if(URLactual=='http://app2.bodeganet.cl/' || 
   URLactual=='https://app2.bodeganet.cl/' ||
   URLactual=='http://www.app2.bodeganet.cl/' ||
   URLactual=='https://www.app2.bodeganet.cl/'){
    result=true
} else {
    result=false
};
export default result