<template>
  <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Configuracion</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">Configuracion</h3> <!-- Mobile Screen -->
    <div class="mt-5">
      <v-bottom-navigation :value="value" color="success">
        <v-btn @click="OpenComponent(0)" :disabled="comp0">      
          <span>Proveedor</span>
          <v-icon>{{ProveedorIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(1)" :disabled="comp1">
          <span>Cliente</span>
          <v-icon>{{ClienteIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(2)" :disabled="comp2">
          <span>Usuario</span>
          <v-icon>{{UsuarioIcon}}</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <div class="mt-2">
      
          <component v-bind:is="componente" :usu="usu" :contrato="contrato"></component>
       
      </div>
    </div>
    <dialogo :loading="loading"  />
  </div>
</template>

<script>
import axios from 'axios';
import Proveedor from '@/components/Proveedor.vue';
import Cliente from '@/components/Cliente.vue';
import Usuario from '@/components/Usuario.vue';
import dialogo from '@/components/dialogo.vue';
export default {
  name: 'Setup',
  components: { Proveedor, Cliente, Usuario ,dialogo},
  props:['usu','contrato'],
  data(){
    return{
      loading:false,
      value:null,
      componente:'',
      IconSelect:'check_circle',
      IconNoSelect:'person_pin',
      ProveedorIcon:'person_pin',
      ClienteIcon:'person_pin',
      UsuarioIcon:'person_pin',
      comp0:true,
      comp1:true,
      comp2:true,
    }
  },
  async mounted(){
    await this.entrada();
  },
  methods:{
    async entrada(){
      this.loading=true;
      const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
      const datos=response.data;
      if (datos[0].existe=='si'){
        if(datos[0].Permiso_6_1!=0) {
          this.comp0=false;
        };
        if(datos[0].Permiso_6_2!=0) {
          this.comp1=false;
        };
        if(datos[0].Permiso_6_3!=0) {
          this.comp2=false;
        }; 
      }
      this.moduloActivo();
    },
    moduloActivo(){
      if (this.comp0!=true){
          this.componente='Proveedor';
          this.ProveedorIcon=this.IconSelect;
          this.ClienteIcon=this.IconNoSelect;
          this.UsuarioIcon=this.IconNoSelect;
          this.value=0
      } else {
        if (this.comp1!=true){
          this.componente='Cliente';
          this.ProveedorIcon=this.IconNoSelect;
          this.ClienteIcon=this.IconSelect;
          this.UsuarioIcon=this.IconNoSelect;
          this.value=1
        } else {
          if (this.comp2!=true){
            this.componente='Usuario';
            this.ProveedorIcon=this.IconNoSelect;
            this.ClienteIcon=this.IconNoSelect;
            this.UsuarioIcon=this.IconSelect;
            this.value=2
          } else {
            this.ProveedorIcon=this.IconNoSelect;
            this.ClienteIcon=this.IconNoSelect;
            this.UsuarioIcon=this.IconNoSelect;
          }
        }
      }
      this.loading=false;
    },
    OpenComponent(value){
      let vComponente;
      switch (value) {
        case 0:
          vComponente='Proveedor';
          this.ProveedorIcon=this.IconSelect;
          this.ClienteIcon=this.IconNoSelect;
          this.UsuarioIcon=this.IconNoSelect;
          break;
        case 1:
          vComponente='Cliente';
          this.ProveedorIcon=this.IconNoSelect;
          this.ClienteIcon=this.IconSelect;
          this.UsuarioIcon=this.IconNoSelect;
          break;
        case 2:
          vComponente='Usuario';
          this.ProveedorIcon=this.IconNoSelect;
          this.ClienteIcon=this.IconNoSelect;
          this.UsuarioIcon=this.IconSelect;
          break;
      }
      this.componente=vComponente;
    }
  }
}
</script>