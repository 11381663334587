<template>
    <div>
        <h3>Utilidad Mensual ({{Mes}}-{{Anio}})
          <v-progress-linear v-if="rueda===true" rounded height="2" indeterminate color="primary"></v-progress-linear>
        </h3>
        <v-divider></v-divider>
        <div class="mt-1">
        <v-data-table :headers="headerVentas" :items="itemVentas" class="elevation-1" hide-default-footer dark>
          <template v-slot:item.tPromedio="{ item }">
            <v-chip color="indigo" dark> {{ item.tPromedio }}</v-chip>
          </template>
        </v-data-table>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'UtilidadMes',
  props:['usu','contrato'],
  data(){
    return {
      //contrato:'123',
      varVentas:0,
      varCompras:0,
      varUtilidad:0,
      varPromedio:0,
      varIva:0,
      varDiferencia:0,
      varImpuesto:0,
      //varResto:0,
      Mes:'',
      Anio:'',
      headerVentas:[
        {text: 'Compras ($)',align: 'center',sortable: false, value: 'tCompras'},
        {text: 'Ventas ($)',align: 'center',sortable: false, value: 'tVentas'},
        {text: 'Impuesto ($)',align: 'center',sortable: false, value: 'tImpuesto'},
        {text: 'Utilidad ($)',align: 'center',sortable: false, value: 'tUtilidad'},
        {text: 'Promedio Mes ($)',align: 'center',sortable: false, value: 'tPromedio'}
      ],
      itemVentas: [],
      rueda:true,
      // new Utilidades
      codigos:[],
      entradas:[],
      salidas:[]
    }
  },
  async mounted(){
    this.UtilidadMensual();   
  },
  methods:{
    async UtilidadMensual(){
      this.rueda=true;
      const fecha=new Date();
      const tFecha = fecha.toISOString();
      const hoy=tFecha.substring(0,10);
      const anio=tFecha.substring(0,4);
      const mes=tFecha.substring(6,7);

      switch (mes) {
        case '1':
          this.Mes='Ene';
          break;
        case '2':
          this.Mes='Feb';
          break;
        case '3':
          this.Mes='Mar';
          break;
        case '4':
          this.Mes='Abr';
          break;
        case '5':
          this.Mes='May';
          break;
        case '6':
          this.Mes='Jun';
          break;
        case '7':
          this.Mes='Jul';
          break;
        case '8':
          this.Mes='Ago';
          break;
        case '9':
          this.Mes='Sep';
          break;
        case '10':
          this.Mes='Oct';
          break;
        case '11':
          this.Mes='Nov';
          break;
        case '12':
          this.Mes='Dic';
          break;
      }
      this.Anio=anio;
      await this.Util(anio,mes);
      this.rueda=false;
    },
    async Util(anio,mes){
      try {
        // obtenemos todos los sku
        const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/utilidad/sku/all/'+ this.contrato);
        const datos=response.data;
        if(datos.length!=0){
          this.codigos=datos;
          //console.log(this.codigos);
          // obtenemos todos las entradas
          const response1 = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/utilidad/entradas/'+ this.contrato);
          const datos1=response1.data;
          if(datos1.length!=0){
              this.entradas=datos1;
              //console.log(this.entradas);
          };
          // obtenemos las salidas
          const response2 = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/utilidad/salidas/'+ this.contrato);
          const datos2=response2.data;
          if(datos2.length!=0){
              this.salidas=datos2;
              //console.log(this.salidas);
          };
          let HoraInicio = new Date();
          console.log('INICIO');
          console.log(HoraInicio);
          //comenzamos el recorrido por sku
          let arraySalida=[];
          this.codigos.forEach(element => {
            const nSku=element.Sku;
            //rescatamos las entradas
            const arrayIN = this.entradas.filter(res => res.Sku==nSku);
            if(arrayIN){
              // preparamos la entrada
              let ini=0;
              let fin=0;
              let valor=0;
              let arrayEntrada=[];
              for (let index = 0; index < arrayIN.length; index++) {
                  const res = arrayIN[index];
                  ini=fin+1;
                  fin=fin+parseFloat(res.Cantidad);
                  valor=res.Precio;
                  arrayEntrada.push({
                      'sku':res.Sku,
                      'inicio':parseFloat(ini),
                      'final':parseFloat(fin),
                      'precio':parseFloat(valor)
                  });                
              };
              // recorremos las Salidas
              const arrayOUT = this.salidas.filter(res => res.Sku==nSku);
              if(arrayOUT){
                let fec='';
                let rango=0;
                let precio_venta=0;
                let precio_compra=0;
                let utilidad=0;
                for (let index = 0; index < arrayOUT.length; index++) {
                  const res1 = arrayOUT[index];
                  fec=res1.Fecha;
                  if(res1.Origen=='Venta' || res1.Origen=='Devolucion'){
                    precio_venta=parseFloat(res1.Precio);
                    for (let index = 0; index < res1.Cantidad; index++) {  
                      if(res1.Origen=='Venta'){
                          rango=parseInt(rango)+1;
                          //buscar precio compra segun rango
                          const found = arrayEntrada.find(res => rango>=res.inicio && rango<=res.final);
                          if(found){ precio_compra=parseFloat(found.precio) };
                          utilidad=parseFloat(precio_venta)-parseFloat(precio_compra);
                      };
                      if(res1.Origen=='Devolucion'){
                          rango=parseInt(rango)-1;
                          //buscar precio compra segun rango
                          const found = arrayEntrada.find(res => rango>=res.inicio && rango<=res.final);
                          if(found){ precio_compra=parseFloat(found.precio) };
                          utilidad=parseFloat(precio_compra)-parseFloat(precio_venta);
                          // cambiar signo por devolucion
                          precio_venta=parseFloat(precio_venta)*-1
                      }             
                      //array utilidad
                      arraySalida.push({
                          'Fecha':fec,
                          'Sku': res1.Sku,
                          'PrecioCompra':precio_compra,
                          'PrecioVenta':precio_venta,
                          'Utilidad':utilidad,
                          'Origen':res1.Origen
                      })
                    }
                  };
                  if(res1.Origen=='Egreso'){
                    rango=parseInt(rango)-parseFloat(res1.Cantidad);
                  }            
                }
              }
            }
          });
          // filtrar la salida segun lo necesitado
          let filtro = arraySalida.filter(res => parseInt(new Date(res.Fecha+'T00:00:00').getFullYear())==parseInt(anio) &&  parseInt(new Date(res.Fecha+'T00:00:00').getMonth()+1)==parseInt(mes));
          filtro.forEach(result => {
            this.varCompras=this.varCompras+result.PrecioCompra;
            this.varVentas=this.varVentas+result.PrecioVenta;
            this.varUtilidad=this.varUtilidad+result.Utilidad;
          });
          this.varPromedio= Math.round(this.varUtilidad/12);
          this.itemVentas=[];
          this.itemVentas.push({
            tCompras:new Intl.NumberFormat("de-DE").format(this.varCompras),
            tVentas:new Intl.NumberFormat("de-DE").format(this.varVentas),
            tUtilidad:new Intl.NumberFormat("de-DE").format(this.varUtilidad),
            tPromedio:new Intl.NumberFormat("de-DE").format(this.varPromedio)
          });
          console.log('FIN');
          let now = new Date();
          console.log(now);
        };                
      } catch (error) {
          console.log(error)
      }
    },
  }
}
</script>