import { render, staticRenderFns } from "./DashProductos.vue?vue&type=template&id=343155ce&scoped=true&"
import script from "./DashProductos.vue?vue&type=script&lang=js&"
export * from "./DashProductos.vue?vue&type=script&lang=js&"
import style0 from "./DashProductos.vue?vue&type=style&index=0&id=343155ce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "343155ce",
  null
  
)

export default component.exports