<template>
  <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Inventario</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">Inventario</h3> <!-- Mobile Screen -->
    <div class="mt-5">
      <v-bottom-navigation :value="value" color="success">
        <v-btn @click="OpenComponent(0)" :disabled="comp0">      
          <span>Stock</span>
          <v-icon>{{StockIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(1)" :disabled="comp1">
          <span>Traspaso</span>
          <v-icon>{{IgresoIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(2)" :disabled="comp2">
          <span>Ajuste</span>
          <v-icon>{{EgresoIcon}}</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <div class="mt-2">
       
          <component v-bind:is="componente" :usu="usu" :contrato="contrato"></component>
      
      </div>
    </div>
    <dialogo :loading="loading"  />
  </div>
</template>

<script>
import axios from 'axios';
import Stock from '@/components/Stock.vue';
// import Ingreso from '@/components/Ingreso.vue';
// import Egreso from '@/components/Egreso.vue';
import Traspaso from '@/components/Traspaso.vue';
import Ajuste from '@/components/Ajuste.vue';
import dialogo from '@/components/dialogo.vue';
export default {
  name: 'Inventario',
  components: { Stock, Traspaso, Ajuste, dialogo},
  props: ['usu','contrato'],
  data(){
    return{
      loading:false,
      value:null,
      componente:'',
      IconSelect:'check_circle',
      StockIcon:'store',
      IgresoIcon:'repeat',
      EgresoIcon:'vertical_align_center',
      StockIconDefault:'store',
      IgresoIconDefault:'repeat',
      EgresoIconDefault:'vertical_align_center',
      comp0:true,
      comp1:true,
      comp2:true,
    }
  },
  async mounted(){
    await this.entrada();
  },
  methods:{
    async entrada(){
      this.loading=true;
      const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
      const datos=response.data;
      if (datos[0].existe=='si'){
        if(datos[0].Permiso_4_1!=0) {
          this.comp0=false;
        };
        if(datos[0].Permiso_4_2!=0) {
          this.comp1=false;
        };
        if(datos[0].Permiso_4_3!=0) {
          this.comp2=false;
        }; 
      } 
      this.moduloActivo();
    },
    moduloActivo(){
      if (this.comp0!=true){
          this.componente='Stock';
          this.StockIcon=this.IconSelect;
          this.IgresoIcon=this.IgresoIconDefault;
          this.EgresoIcon=this.EgresoIconDefault;
          this.value=0
      } else {
        if (this.comp1!=true){
          this.componente='Traspaso';
          this.StockIcon=this.StockIconDefault;
          this.IgresoIcon=this.IconSelect;
          this.EgresoIcon=this.EgresoIconDefault;
          this.value=1
        } else {
          if (this.comp2!=true){
            this.componente='Ajuste';
            this.StockIcon=this.StockIconDefault;
            this.IgresoIcon=this.IgresoIconDefault;
            this.EgresoIcon=this.IconSelect;
            this.value=2
          } else {
            this.StockIcon=this.StockIconDefault;
            this.IgresoIcon=this.IgresoIconDefault;
            this.EgresoIcon=this.EgresoIconDefault;
          }
        }
      }
      this.loading=false;
    },
    OpenComponent(value){
      let vComponente;
      switch (value) {
        case 0:
          vComponente='Stock';
          this.StockIcon=this.IconSelect;
          this.IgresoIcon=this.IgresoIconDefault;
          this.EgresoIcon=this.EgresoIconDefault;
          break;
        case 1:
          vComponente='Traspaso';
          this.StockIcon=this.StockIconDefault;
          this.IgresoIcon=this.IconSelect;
          this.EgresoIcon=this.EgresoIconDefault;
          break;
        case 2:
          vComponente='Ajuste';
          this.StockIcon=this.StockIconDefault;
          this.IgresoIcon=this.IgresoIconDefault;
          this.EgresoIcon=this.IconSelect;
          break;
      }
      this.componente=vComponente;
    }
  }
}
</script>