<template>
    <div style="margin-top:-15px">
        <h3>Ventas Pendientes</h3>
        <v-divider></v-divider>
        <v-row style="margin-top:-5px">
            <v-col cols="12" md="4">
                <v-card color="indigo" dark max-height="100">
                    <v-card-title>
                        <v-icon large>store</v-icon>
                        <v-spacer></v-spacer>
                        <h3>{{local}}</h3>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <strong style="font-family:calibri;font-size:9pt">LOCAL</strong>
                        <v-icon @click="VerLocal()" class="ml-2" color="white" small>refresh</v-icon>
                        <v-spacer></v-spacer>
                        <v-btn @click="verCotiza()" text x-small>detalle</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card color="red" dark max-height="100">
                    <v-card-title>
                        <v-icon large>shopping_cart</v-icon>
                        <v-spacer></v-spacer>
                        <h3>{{web}}</h3>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <h6 style="font-family:calibri;font-size:9pt">WEB</h6>
                        <v-spacer></v-spacer>
                        <v-btn text x-small>detalle</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card color="orange" dark max-height="100">
                    <v-card-title>
                        <v-icon large>notifications</v-icon>
                        <v-spacer></v-spacer>
                        <h3>{{comercio}}</h3>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <strong style="font-family:calibri;font-size:9pt">COMERCIO</strong>
                        <v-spacer></v-spacer>
                        <v-btn @click="verEcommerce()" text x-small>detalle</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" md="12">
                <v-card color="success" dark>
                    <v-card-title>
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-icon class="ml-2">monetization_on</v-icon>
                                <span class="ml-2 text-caption">Ventas</span>
                            </v-col>
                            <v-col cols="8" md="5">
                                <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">                                        
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip class="ml-2 text-caption" small @click="menu1 = true" v-bind="attrs" v-on="on" outlined color="white">
                                            <v-icon class="ml-2">events</v-icon>
                                            {{date1}}
                                        </v-chip>                                        
                                    </template>
                                <v-date-picker v-model="date1" @input="menu1 = false" no-title scrollable></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-divider inset vertical color="white"></v-divider>
                            <v-col cols="3" md="3" class="text-right">
                                <v-btn @click="verVentas(date1)" text x-small>detalle</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <!-- dialogo ventas -->
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <span class="text-body-2 teal--text">Total Ventas</span>
                <v-spacer></v-spacer>
                <v-btn @click="dialog=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table dark :search="likesearch" :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                    <template v-slot:item.accion="{ item }">
                        <a target="_blank" :href="item.ruta"><v-icon small>print</v-icon></a>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" class='mt-3'>
                        <a class="ml-3" v-if="descargaCSV===false" href="javascript:void(0)" @click="fileCSV()"><img src="@/assets/csv.png" width="16px" height="20px"/></a>
                        <a class="ml-3" v-else :href="rutaCSV" style="text-decoration: none"><v-icon color='teal'>cloud_download</v-icon><span class="ml-2 text-caption">Descarga</span></a>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <!-- dialogo Cotiza -->
        <v-dialog v-model="dialogCotiza" transition="dialog-bottom-transition" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <span class="text-body-2 teal--text">Cotizaciones Pendientes</span>
                <v-spacer></v-spacer>
                <v-btn @click="dialogCotiza=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table dark :search="likesearch" :headers="headersCotiza" :items="itemsCotiza" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                    <template v-slot:item.accion="{ item }">
                        <v-icon small @click="deleteCotiza(item.numero)">delete</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                    <v-col cols="10" md="4">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <!-- dialogo Ecommerce -->
        <v-dialog v-model="dialogEcommerce" transition="dialog-bottom-transition" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <span class="text-body-2 teal--text">Ventas x Facturar</span>
                <v-spacer></v-spacer>
                <v-btn @click="dialogEcommerce=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table dark :search="likesearch" :headers="headersEcommerce" :items="itemsEcommerce" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1 text-size" @page-count="pageCount = $event">
                    <template v-slot:item.accion>
                        <v-icon small class="text grey--text">block</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                    <v-col cols="10" md="4">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Dato" dense required></v-text-field>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 33px !important;
    /* background-color: lightyellow; */
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name: 'DashCompraVenta',
    components:{dialogo, snack},
    props: ['usu','contrato'],
    data(){
        return{
            descargaCSV:false,
            rutaCSV:'',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            headers: [
                { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
                { text: 'Documento', align: 'start', sortable: true, value: 'documento' },
                { text: 'Numero', align: 'start', sortable: true, value: 'numero' },
                { text: 'Cliente', align: 'start', sortable: true, value: 'cliente' },
                { text: 'Total', align: 'start', sortable: true, value: 'total' },
                { text: 'Pago', align: 'start', sortable: true, value: 'pago' },
                { text: 'Vendedor', align: 'start', sortable: true, value: 'usuario' },
                { text: 'Accion', align: 'center', sortable: false, value: 'accion' },
            ],
            headersCotiza: [
                { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
                { text: 'Numero', align: 'start', sortable: true, value: 'numero' },
                { text: 'Cliente', align: 'start', sortable: true, value: 'cliente' },
                { text: 'Neto', align: 'start', sortable: true, value: 'neto' },
                { text: 'Vendedor', align: 'start', sortable: true, value: 'usuario' },
                { text: 'Accion', align: 'center', sortable: false, value: 'accion' },
            ],
            headersEcommerce: [
                { text: 'Fecha', align: 'center', sortable: true, value: 'fecha' },
                { text: 'Numero', align: 'start', sortable: true, value: 'numero' },
                { text: 'Cliente', align: 'start', sortable: true, value: 'cliente' },
                { text: 'Total', align: 'start', sortable: true, value: 'total' },
                { text: 'Vendedor', align: 'start', sortable: true, value: 'portal' },
                { text: 'Accion', align: 'center', sortable: false, value: 'accion' },
            ],
            items: [],
            itemsCotiza: [],
            itemsEcommerce: [],
            dialog:false,
            dialogCotiza:false,
            dialogEcommerce:false,
            likesearch:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            local:0,
            web:0,
            comercio:0,
            menu1:false,
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        }
    },
    mounted(){
        this.TotalCotiza();
        //this.OrdersEcommerce(); 
        //this.TotalEcommerce();    
    },
    methods:{
        async fileCSV(){
            this.rutaCSV='javascript:void(0)';
            var date = new Date();
            const formatDate = (current_datetime)=>{
                let formatted_date = current_datetime.getFullYear() + "" + (current_datetime.getMonth() + 1) + "" + current_datetime.getDate() + "" + current_datetime.getHours() + "" + current_datetime.getMinutes() + "" + current_datetime.getSeconds();
                return formatted_date;
            };
            let csv;
            csv=[];
            //titulo
            csv.push({
                        'fecha':'Fecha',
                        'documento':'Documento',
                        'numero':'Numero',
                        'cliente':'Cliente',
                        'total':'Total',
                        'pago':'Pago',
                        'usuario':'Vendedor'
                    });
            this.items.forEach(element => {
                        csv.push({
                            'fecha':element.fecha,
                            'documento':element.documento,
                            'numero':element.numero,
                            'cliente':element.cliente,
                            'total':element.total,
                            'pago':element.pago,
                            'usuario':element.usuario
                        })
                    });
            const nameFile = 'ventas_'+formatDate(date);
            const params = {'datos':csv,'contrato':this.contrato,'archivo':nameFile};
            const response = await axios.post(process.env.VUE_APP_URL + '/public/api/inv/csv/nuevo',params);
            if (response.data=='si'){
                this.rutaCSV=process.env.VUE_APP_URL + '/public/buzon/'+this.contrato+'/'+nameFile+'.csv';
                this.descargaCSV=true
            }
        },
        async VerLocal(){
            this.loading=true;
            await this.TotalCotiza();
            this.loading=false;
        },
        async TotalCotiza(){
            const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/ordenes/n/'+this.contrato);
            const datos=response.data;
            this.local=0;
            if (datos[0].existe=='si'){ 
                this.local=parseInt(datos[0].Total)
            }
           //setTimeout(() => {this.TotalCotiza()}, 1000);
        },
        async OrdersEcommerce(){
            await axios.get('http://www.app.bodeganet.cl/ApiEcomerce/public/api/ML/orders/'+this.contrato);
            //setTimeout(() => {this.OrdersEcommerce()}, 30000);
        },
        async TotalEcommerce(){
            const response = await axios.get('http://www.app.bodeganet.cl/ApiEcomerce/public/api/ML/total/orders/'+this.contrato);
            const datos=response.data;
            this.comercio=0;
            if (datos[0].existe=='si'){ 
                this.comercio=parseInt(datos[0].total)
            }
           //setTimeout(() => {this.TotalEcommerce()}, 40000);
        },
        async verVentas(fechaventa){
            //console.log(fechaventa);
            this.descargaCSV=false;
            this.rutaCSV='';
            this.dialog=true
            this.loading=true;
            const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/DTE/'+this.contrato);
            const datos=response.data;
            this.items=[];
            if (datos[0].existe=='si'){ 
                for (let index = 0; index < datos.length; index++) {
                    const element = datos[index];
                    if (element.Fecha==fechaventa) {
                        this.items.push({
                            'fecha':element.Fecha,
                            'documento':element.Documento,
                            'numero':element.Numero,
                            'cliente':element.Cliente,
                            'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                            'pago':element.Pago,
                            'usuario':element.Usuario,
                            'ruta':element.RutaPdf
                        })
                    }
                }
                // datos.forEach(element => {
                //     this.items.push({
                //         'fecha':element.Fecha,
                //         'documento':element.Documento,
                //         'numero':element.Numero,
                //         'cliente':element.Cliente,
                //         'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                //         'pago':element.Pago,
                //         'usuario':element.Usuario,
                //         'comision':new Intl.NumberFormat("de-DE").format(parseFloat(element.Comision)),
                //         'ruta':element.RutaPdf
                //     })
                // });
            };
            this.loading=false;
        },
        async verCotiza(){
            this.dialogCotiza=true
            this.loading=true;
            const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/ordenes/'+this.contrato);
            const datos=response.data;
            this.itemsCotiza=[];
            if (datos[0].existe=='si'){   
                for (let index = 0; index < datos.length; index++) {
                    const element = datos[index];
                    this.itemsCotiza.push({
                        'fecha':element.Fecha,
                        'numero':element.Orden,
                        'cliente':element.Nombre,
                        'neto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                        'usuario':element.Usuario,
                    });                    
                }              
                // datos.forEach(element => {
                //     this.itemsCotiza.push({
                //         'fecha':element.Fecha,
                //         'numero':element.Orden,
                //         'cliente':element.Nombre,
                //         'neto':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                //         'usuario':element.Usuario,
                //     });
                // });
            };
            this.loading=false;
        },
        async deleteCotiza(n){
            this.loading=true;
            const response = await axios.delete(process.env.VUE_APP_URL + '/public/api/inv/orden/delete/'+this.contrato+'/'+n);
            const datos=response.data;
            if (datos=='ok'){
                this.itemsCotiza = this.itemsCotiza.filter(function(car) {
                    return car.numero !== n; 
                });
                //actualiza ecomerce si existe
                await axios.put('http://www.app.bodeganet.cl/ApiEcomerce/public/api/ML/products/orders/status/V/'+n+'/'+this.contrato);
            };
            this.loading=false;
        },
        async verEcommerce(){
            this.dialogEcommerce=true
            this.loading=true;
            const response = await axios.get('http://www.app.bodeganet.cl/ApiEcomerce/public/api/ML/details/orders/'+this.contrato);
            const datos=response.data;
            this.itemsEcommerce=[];
            if (datos[0].existe=='si'){   
                for (let index = 0; index < datos.length; index++) {
                    const element = datos[index];
                    this.itemsEcommerce.push({
                        'fecha':element.Fecha,
                        'numero':element.Orden,
                        'cliente':element.Nombre,
                        'total':new Intl.NumberFormat("de-DE").format(parseFloat(element.Total)),
                        'portal':element.Portal,
                    });                    
                }              
            };
            this.loading=false;
        },
    }
}
</script>