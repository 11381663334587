<template>
    <div>
        <v-card>
            <v-card-title class="blue-grey white--text">
                CREAR
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="sku" :counter="15" label="SKU" :disabled="disabledSku" :filled="filledSku" outlined dense required></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1">
                                <v-btn @click="verSku(sku)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                    Existe !
                                </v-alert>
                                <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                    Nuevo !
                                </v-alert>
                                <v-alert class='text-caption' v-else dense text type="info">
                                    <span class="text-caption grey--text">Sku: 99999</span>
                                </v-alert>
                            </v-col >
                            <v-col cols="12" md="4">
                                <v-text-field class='text-caption' v-model="nombre" :counter="50" label="Nombre Producto" outlined dense :disabled="disabledResto" :filled="filledResto"></v-text-field>
                            </v-col>
                            <v-col cols="8" md="2">
                                <v-select class='text-caption' v-on:change="OpenDialog(categoria)" v-model="categoria" :items="listacategoria" label="Categoria" dense outlined :disabled="disabledResto" :filled="filledResto"></v-select>
                            </v-col>
                            <v-col cols="4" md="1" class="text-center">
                                <v-icon @click="nuevaCategoria()" class="mt-2" color="indigo">add_circle_outline</v-icon>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="mt-1">
                            <v-col cols="12" md="5">
                                <v-textarea class='text-caption' no-resize rows="5" outlined v-model="descripcion" :counter="2500" label="Descripcion" :disabled="disabledResto" :filled="filledResto"></v-textarea>                                
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <span class='text-caption'>Imagenes del Producto</span>
                                        <v-btn class="ml-2" fab small dark @click="imagen=true" color="indigo"><v-icon>photo_library</v-icon></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="7">
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field class='mt-2 text-caption' v-model="caracteristica" :counter="50" label="Caracteristica" outlined dense :disabled="disabledResto" :filled="filledResto"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field class='mt-2 text-caption' v-model="universal" label="Cod.Universal" outlined dense :disabled="disabledResto" :filled="filledResto"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field class='mt-2 text-caption' @keypress="onlyNumber" v-model="barra" label="Cod.Barra" outlined dense :disabled="disabledResto" :filled="filledResto"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="preciolocal" label="Precio Local" outlined dense :disabled="disabledResto" :filled="filledResto"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="preciomayor" label="Precio Mayor" outlined dense :disabled="disabledResto" :filled="filledResto"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field class='text-caption' @keypress="onlyNumber" v-model="preciooferta" label="Precio Oferta" outlined dense :disabled="disabledResto" :filled="filledResto"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-select class='text-caption' v-model="estado" :items="estados" label="Estado" dense outlined :disabled="disabledResto" :filled="filledResto"></v-select>
                                        <!-- <v-text-field class='text-caption' @keypress="onlyNumber" v-model="critico" label="Stock Critico" outlined dense disabled filled></v-text-field> -->
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <div class="float-end">
                                            <v-btn @click="newProducto()" class="mr-5" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                                            <v-btn @click="saveProducto()" class="mr-5" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions style="margin-top:-25px">
            </v-card-actions>
        </v-card>
        <!-- DIALOGO NEW CATEGORIA -->
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Mantenedor de Categorias</v-toolbar>
            <v-card-text class="mt-5">
                
                <v-row>
                    <v-col cols="12" md="5">
                        <v-text-field class='text-caption' v-model="newcategoria" label="Nueva Categoria" :disabled="disabledCategoria" :filled="filledCategoria" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-alert class='text-caption' v-if="alertacategoria=='si'" dense text type="success">
                            Categoria Creada !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else-if="alertacategoria=='no'" dense text type="error">
                            Sin Categoria !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else dense text type="info">
                            Dato Obligatorio !!!
                        </v-alert>
                    </v-col>
                    <v-col cols="12" md="2">
                        <div v-if="alertacategoria!='si'">
                           <v-btn @click="newCategoria()" small fab dark color="indigo"><v-icon>playlist_add</v-icon></v-btn>
                            <!-- <v-btn @click="newCategoria()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn> -->
                        </div>
                        <div v-else>
                            <v-btn small fab dark color="grey"><v-icon>playlist_add</v-icon></v-btn>
                            <!-- <v-btn small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn> -->
                        </div>
                    </v-col>
                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="12" md="12">
                        <v-data-table dense dark :headers="categoryHead" :items="categoryList" :page.sync="page" :items-per-page="itemsPerPage"  @page-count="pageCount = $event" hide-default-footer class="elevation-1 text-size">
                            <template v-slot:item.accion="{ item }">
                                <v-icon small class="white--text" @click="deleteCategory(item)">delete</v-icon>
                            </template>
                        </v-data-table>
                        <v-pagination v-model="page" :length="pageCount" :total-visible="5"></v-pagination>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="CloseDialog()" color="indigo">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- DIALOGO IMAGENES -->
        <v-dialog v-model="imagen" transition="dialog-bottom-transition" max-width="400" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Imagenes del Producto</v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="9" md="9">
                        <v-file-input label="Imagen" dense v-model="archivo" :disabled="disabledResto" :filled="filledResto"></v-file-input>
                    </v-col>
                        <v-col cols="3" md="3">
                            <div class="mt-2">
                            <v-icon color="success" @click="upImagen()">check_circle</v-icon>
                            <v-icon color="red" @click="deleteImagen()">delete</v-icon>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" md="4">
                        <span v-if="fotos.length>0">
                        <v-img :src="fotos[0].src" max-width="55" max-height="55"></v-img>
                        </span>
                        <v-skeleton-loader v-else class="mx-auto" max-width="55" max-height="55" type="card"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="4" md="4">
                        <span v-if="fotos.length>1">
                        <v-img :src="fotos[1].src" max-width="55" max-height="55"></v-img>
                        </span>
                        <v-skeleton-loader v-else class="mx-auto" max-width="55" max-height="55" type="card"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="4" md="4">
                        <span v-if="fotos.length>2">
                        <v-img :src="fotos[2].src" max-width="55" max-height="55"></v-img>
                        </span>
                        <v-skeleton-loader v-else class="mx-auto" max-width="55" max-height="55" type="card"></v-skeleton-loader>
                    </v-col>
                    <!-- <v-col cols="4" md="2">
                        <span v-if="fotos.length>3">
                        <v-img :src="fotos[3].src" max-width="55" max-height="55"></v-img>
                        </span>
                        <v-skeleton-loader v-else class="mx-auto" max-width="55" max-height="55" type="card"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="4" md="2">
                        <span v-if="fotos.length>4">
                        <v-img :src="fotos[4].src" max-width="55" max-height="55"></v-img>
                        </span>
                        <v-skeleton-loader v-else class="mx-auto" max-width="55" max-height="55" type="card"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="4" md="2">
                        <span v-if="fotos.length>5">
                        <v-img :src="fotos[5].src" max-width="55" max-height="55"></v-img>
                        </span>
                        <v-skeleton-loader v-else class="mx-auto" max-width="55" max-height="55" type="card"></v-skeleton-loader>
                    </v-col> -->
                </v-row>                                      
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="imagen=false" color="indigo">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"/>
    </div>
</template>
<style scoped>
.text-size >>> td {
    font-size: 9pt !important;
    height: 25px !important;
    /*background-color: lightyellow;*/ 
}
.col-color >>> th {
    background-color: lightgrey;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'CrearProducto', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            //contrato:'123',
            alertacategoria:'',
            newcategoria:'',
            dialog:false,
            disabledCategoria:false,
            filledCategoria:false,
            disabledResto:true,
            filledResto:true,
            existe:'',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            disabledSku:false,
            filledSku:false,
            sku:'',
            nombre:'',
            categoria:'',
            ncategoria:0,
            listacategoria:[],
            descripcion:'',
            caracteristica:'',
            preciolocal:'0',
            preciomayor:'0',
            preciooferta:'0',
            critico:'0',
            estados:['V','N'],
            estado:'V',
            archivo:'',
            fotos:[],
            imagen:false,
            universal:'',
            barra:0,
            impuesto:0,
            ventaLocal:'',
            ventaMayor:'',
            ventaOferta:'',

            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            categoryHead:[
                { text: 'ID', align: 'center', sortable: false, value: 'id' },
                { text: 'Descripcion', align: 'start', sortable: false, value: 'name' },
                { text: '', align: 'center', sortable: false, value: 'accion' }
            ],
            categoryList:[],
        }
    },
    async mounted(){
        await this.SelectCategorias();
        await this.buscaImpuesto();
    },
    methods:{
        async buscaImpuesto(){
            const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/impuesto/'+this.contrato);
            const datos=response.data;
            this.impuesto=0;
            if (datos[0].existe=='si'){
                this.impuesto=datos[0].Iva
            }
        },
        restoDisabled(bool){
            this.disabledResto=bool,
            this.filledResto=bool
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        async SelectCategorias(){
            const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/categorias/'+ this.contrato);
            const datos=response.data;
            this.listacategoria=[];
            this.categoryList=[];
            //this.listacategoria.push('Nueva');
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.listacategoria.push(element.Nombre);
                    this.categoryList.push({'id':element.ID,'name':element.Nombre});
                });
            }
        },
        async numeroCategoria(txtcategoria){
            const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/categorias/'+ this.contrato);
            const datos=response.data;
            //console.log('2');
            datos.forEach(element => {
                if(element.Nombre==txtcategoria){
                    this.ncategoria=element.ID
                }
            });
            //console.log(this.ncategoria)
        },
        async nombreCategoria(numeroCategoria){
            const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/categorias/'+ this.contrato);
            const datos=response.data;
            //console.log('3');
            datos.forEach(element => {
                if(element.ID==numeroCategoria){
                    this.categoria=element.Nombre
                }
            });
            //console.log(this.categoria)
        },        
        OpenDialog(a) {
            this.alertacategoria='';
            this.newcategoria='';
            this.dialog=false;
            //console.log(a);
            this.numeroCategoria(a);
        },
        nuevaCategoria(){
            this.dialog=true;
            this.alertacategoria='';
            this.newcategoria='';
        },
        async deleteCategory(item){
            try {
                //validar que la categoria no tenga productos
                const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/categorias/verify/products/'+this.contrato+'/'+item.id);
                const datos=response.data;
                //console.log(datos);
                if(datos[0].existe=='si'){
                    this.Msg='Categoria con Productos vigentes';
                    this.msgbox=true;
                    this.color='red'
                    this.showSnack();
                } else {
                    if(datos[0].existe=='no'){
                        //eliminar la bodega
                        const res = await axios.delete(process.env.VUE_APP_URL + '/public/api/inv/categorias/delete/'+ this.contrato+'/'+item.id);
                        const dat=res.data;
                        if(dat=='ok'){
                            await this.SelectCategorias();
                            // this.categoryList = this.categoryList.filter(function(reg) {
                            //     return reg.id !== item.id; 
                            // });
                            // console.log('categoria eliminado');
                        }
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        CloseDialog() {
           if(this.newcategoria!='' && this.alertacategoria=='si'){
                this.categoria=this.newcategoria;
                this.numeroCategoria(this.categoria);
            };
            this.newcategoria='';
            this.alertacategoria='';
            this.disabledCategoria=false;
            this.filledCategoria=false;
            this.dialog=false;
        },
        async newCategoria(){
            try {
                if(this.newcategoria!=''){
                    this.disabledCategoria=true;
                    this.filledCategoria=true;
                    var parametros={
                        'contrato': this.contrato,
                        'nombre': this.newcategoria};
                    const response = await axios.post(process.env.VUE_APP_URL + '/public/api/inv/categorias/nueva',parametros);
                    const datos=response.data;
                    if (datos=='si'){
                        await this.SelectCategorias();
                        // this.listacategoria.push(this.newcategoria);
                        this.categoria=this.newcategoria;
                        this.alertacategoria='si';                
                    }
                } else {
                    this.alertacategoria='no';
                }                
            } catch (error) {
                console.log(error)
            }
        },
        clsInput(){
            this.nombre='';
            this.categoria='';
            this.ncategria=0;
            this.descripcion='';
            this.caracteristica='';
            this.preciolocal='0';
            this.preciomayor='0';
            this.preciooferta='0';
            this.critico='0';
            this.estado='V';
            this.fotos=[];
            this.archivo='';
            this.universal='';
            this.barra=0;
            this.ventaLocal='';
            this.ventaMayor='';
            this.ventaOferta=''
        },
        async verSku(xSku){
             this.loading=true;
             this.msgbox=false;
             this.clsInput();
            if(xSku.trim()!=''){
                this.sku=this.sku.toUpperCase();
                xSku=xSku.toUpperCase();
                this.disabledSku=true;
                this.filledSku=true;                
                const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/producto/'+this.contrato+'/'+xSku);
                const datos=response.data;
                //console.log(datos);
                if (datos[0].existe=='no'){
                    this.existe='no';
                    this.restoDisabled(false);
                }
                if (datos[0].existe=='error'){
                    this.Msg='Conexion Inestable, intentelo mas tarde'
                    this.color='red';
                    this.msgbox=true;
                    this.showSnack();
                    this.newProducto()
                }
                if (datos[0].existe=='si'){               
                    this.existe='si';
                    this.sku=datos[0].Sku;
                    this.nombre=datos[0].Nombre;
                    this.ncategoria=datos[0].Categoria;
                    this.descripcion=datos[0].Descripcion;
                    this.caracteristica=datos[0].Caracteristica;
                    this.preciolocal=datos[0].PrecioLocal;
                    this.ventaLocal=parseInt(this.preciolocal)+Math.round((parseInt(this.preciolocal)*parseInt(this.impuesto)/100));
                    //this.ventaLocal="Venta: "+new Intl.NumberFormat("de-DE").format(parseFloat(this.ventaLocal));
                    this.ventaLocal="Venta: "+this.ventaLocal;
                    this.preciomayor=datos[0].PrecioMayor;
                    this.ventaMayor=parseInt(this.preciomayor)+Math.round((parseInt(this.preciomayor)*parseInt(this.impuesto)/100));
                    this.ventaMayor="Venta: "+this.ventaMayor;
                    this.preciooferta=datos[0].PrecioOferta;
                    this.ventaOferta=parseInt(this.preciooferta)+Math.round((parseInt(this.preciooferta)*parseInt(this.impuesto)/100));
                    this.ventaOferta="Venta: "+this.ventaOferta;
                    this.critico=datos[0].Critico;
                    this.universal=datos[0].Universal;
                    this.barra=datos[0].Barra;
                    this.estado=datos[0].Estado;
                    await this.nombreCategoria(this.ncategoria);
                    await this.verImagen();
                    this.restoDisabled(false);
                } 
            } else {
                this.existe=''
            }
            this.loading=false;
        },
        async verImagen(){
            this.fotos=[];
            const response = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/producto/imagen/'+this.contrato+'/'+this.sku);
            const res= response.data;
            if (res[0].existe=='si'){
                res.forEach(element => {
                    this.fotos.push({src:element.Imagen});
                });
            }
        },
        async saveProducto(){
             this.loading=true;
             this.msgbox=false;
            if (this.contrato!='' && this.ncategoria!=0 &&
                this.sku!='' && this.nombre!='' && this.categoria!='' &&
                this.descripcion!='' && this.caracteristica!='' && 
                parseInt(this.preciolocal)!=0){                
                this.ventaLocal=parseInt(this.preciolocal)+Math.round((parseInt(this.preciolocal)*parseInt(this.impuesto)/100));
                this.ventaLocal="Venta: "+this.ventaLocal;
                this.ventaMayor=parseInt(this.preciomayor)+Math.round((parseInt(this.preciomayor)*parseInt(this.impuesto)/100));
                this.ventaMayor="Venta: "+this.ventaMayor;
                this.ventaOferta=parseInt(this.preciooferta)+Math.round((parseInt(this.preciooferta)*parseInt(this.impuesto)/100));
                this.ventaOferta="Venta: "+this.ventaOferta;
                var parametros={
                    'contrato': this.contrato,
                    'sku': this.sku,
                    'nombre': this.nombre,
                    'categoria': this.ncategoria,
                    'descripcion': this.descripcion,
                    'caracteristica': this.caracteristica,
                    'preciolocal': this.preciolocal,
                    'preciomayor': this.preciomayor,
                    'preciooferta': this.preciooferta,
                    'critico': this.critico,
                    'universal': this.universal,
                    'barra':this.barra,
                    'estado': this.estado
                };
                if (this.existe=='si'){
                    // actualizar
                    const response = await axios.put(process.env.VUE_APP_URL + '/public/api/inv/producto/actualiza/'+this.sku,parametros);
                    const datos=response.data;
                    //console.log(datos);
                    if (datos=='si'){                
                        this.Msg='Modificacion de producto Existoso !!!'
                        this.msgbox=true;
                        this.color='success'
                        this.showSnack();
                    } 
                    if (datos=='error'){
                        this.Msg='Conexion Inestable, intentelo mas tarde'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        this.newProducto()
                    }
                }
                if (this.existe=='no'){
                    // ingresar                    
                    const response = await axios.post(process.env.VUE_APP_URL + '/public/api/inv/producto/nuevo',parametros);
                    const datos=response.data;
                    //console.log(datos);
                    if (datos=='si'){ 
                        await this.stock_inicial();
                        this.existe='si';               
                        this.Msg='Nuevo Producto Existoso !!!'
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                    } 
                    if (datos=='error'){
                        this.Msg='Conexion Inestable, intentelo mas tarde'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        this.newProducto()
                    }
                }
                // if (this.existe==''){
                //     this.Msg='Tiene que validar Rut'
                //     this.msgbox=true;
                //     this.color='red'
                // }            
            } else {
                    this.Msg='Todos los datos obligatorios';
                    this.msgbox=true;
                    this.color='info';
                    this.showSnack();
            }
            this.loading=false;
        },
        async stock_inicial(){
            //buscar todas las bodegas del sistema
            try {
                const response0 = await axios.get(process.env.VUE_APP_URL + '/public/api/inv/bodegas/'+this.contrato);
                const datos0=response0.data;
                if (datos0[0].existe=='si'){
                    for (let index = 0; index < datos0.length; index++) {
                        const element = datos0[index];
                        // agregamos el stock a las sucursales
                        var parametros1={
                            'contrato': this.contrato,
                            'sku': this.sku,
                            'cantidad': '0',
                            'signo': '0+',
                            'bodega': element.ID,
                            'usuario': this.usu,
                            'observa': 'Producto Creado',
                            'origen':'Ingreso'};
                        const response1 = await axios.post(process.env.VUE_APP_URL + '/public/api/inv/stock',parametros1);
                        const datos1=response1.data;
                        if (datos1=='si'){ 
                            console.log('stock creado');
                        } else {
                            console.log('sin stock');
                        }
                    }
                }                
            } catch (error) {
                console.log(error)
            }
        },
        newProducto(){
            this.loading=true;
            this.msgbox=false;
            this.sku='';
            this.clsInput();
            this.disabledSku=false;
            this.filledSku=false;
            this.restoDisabled(true);
            this.existe='';
            this.loading=false;
        },
        async saveImagen(nameArchivo){
            const rutaImagen=process.env.VUE_APP_URL + '/public/images/'+this.contrato+'/'+nameArchivo
            var parametros={
                'contrato': this.contrato,
                'sku': this.sku,
                'ruta': rutaImagen};
            const response = await axios.post(process.env.VUE_APP_URL + '/public/api/inv/producto/imagen',parametros);
            //console.log(response.data);
            if(response.data=='si') {
                this.fotos.push({src:rutaImagen});
                this.Msg='Archivo Ok';
                this.color='success';
                this.msgbox=true;
                this.showSnack();
            } else {
                this.Msg='No se grabo imagen';
                this.color='red';
                this.msgbox=true;
                this.showSnack();                    
            }
        },
        async upImagen(){
            if (this.sku.trim()!=''){
                if(this.fotos.length<6){
                    this.loading=true;
                    if (this.archivo!=''){
                        let InstFormData = new FormData();
                        InstFormData.append('archivo' , this.archivo);
                        InstFormData.append('contrato' , this.contrato);
                        const response= await axios.post(process.env.VUE_APP_URL + '/public/api/server/FileUp', InstFormData , {headers : {'content-type': 'multipart/form-data'}});
                        if (response.data=='ok'){
                            await this.saveImagen(this.archivo.name);
                        }
                        if (response.data=='no'){
                            this.Msg='Archivo no permitido';
                            this.color='info';
                            this.msgbox=true;
                            this.showSnack();                    
                        }
                        if (response.data=='error'){
                            this.Msg='No se guardo Imagen';
                            this.color='red';
                            this.msgbox=true;
                            this.showSnack();                    
                        }
                    } else {
                        this.Msg='Debe seleccionar archivo';
                        this.msgbox=true;
                        this.color='info';
                        this.showSnack();                    
                    }
                    this.loading=false;
                } else {
                    console.log('No Mas');
                }
            } else {
                this.Msg='Ingrese Sku';
                this.msgbox=true;
                this.color='info';
                this.showSnack();                    
            }
        },
        async deleteImagen(){
            this.loading=true;
            if (this.fotos.length>0){
                const response = await axios.delete(process.env.VUE_APP_URL + '/public/api/inv/producto/imagen/delete/'+this.contrato+'/'+this.sku);
                //console.log(response.data);
                if (response.data=='ok'){
                    // this.Msg='Eliminacion Ok';
                    // this.color='success';
                    // this.msgbox=true;
                    // this.showSnack();
                    await this.verImagen()                    
                }
            }
            this.loading=false;
        }
    }
}
</script>
